export const openings = [
    {
      id:'1',
      heading: "Data Warehousing Platform Engineer",
      experiance:'5 years',
      skills: ['Machine learning', 'Data visualization', 'Machine learning algorithms','Statistics', 'Data munging'],
      discription:"Assists with the data platform blueprint and design, encompassing the relevant data platform components. Collaborates with the Integration Architects and Data Architects to ensure cohesive integration between systems and data models.",
      Responsibilities:['Expected to be an SME, collaborate and manage the team to perform.',
        'Responsible for team decisions.',
        'Engage with multiple teams and contribute on key decisions.',
        'Provide solutions to problems for their immediate team and across multiple teams.',
        'Assist with the data platform blueprint and design.',
        'Ensure cohesive integration between systems and data models.',
        'Develop and maintain the data platform.',
        'Ensure the efficiency and effectiveness of the data platform in supporting business operations and decision-making processes. Professional & Technical Skills:',
        'Must To Have Skills:Proficiency in Microsoft Defender.',
        'Strong understanding of statistical analysis and machine learning algorithms.',
        'Experience with data visualization tools such as Tableau or Power BI.',
        'Hands-on implementing various machine learning algorithms such as linear regression, logistic regression, decision trees, and clustering algorithms.'],
      Requirements:[`Bachelor's degree in Computer Science, Engineering, Mathematics, or a related field.`,
        'The candidate should have a minimum of 5 years of experience in Microsoft Defender.',
        '1+ years of experience in using DBT (open source or cloud) to build and manage data pipelines.',
        'Strong SQL skills and familiarity with data warehouse concepts and architectures.',
        'Experience with cloud data platforms such as Snowflake, BigQuery, Redshift, or Databricks.',
        'Experience with version control tools such as Git and CI/CD tools such as GitHub Actions or DBT Cloud.',
        'Excellent communication and documentation skills.',
        'Ability to work independently and collaboratively in a fast-paced environment.']
    },
    {
      id:'2',
      heading: "Data Engineer",
      experiance:'1-2',
      skills: ['SQL Server', 'HANA', 'MySQL', 'PostgreSQL'],
      discription:"We are looking for a data engineer who is proficient in DBT, the open-source tool that simplifies data transformation by allowing data analysts and engineers to transform data by just writing SQL statements. As a data engineer, you will be responsible for building, testing, and maintaining data models that make data accessible and reliable for our client's analytics and business teams. You will also use DBT to apply engineering principles to our analytics infrastructure, such as version control, testing, documentation, and deployment.",
      Responsibilities:['Develop and maintain DBT models that transform raw data into meaningful insights for various business use cases.',
                        'Debug and resolve data quality issues and ensure data accuracy and consistency.',
                        'Implement DBT tests to validate business logic and data integrity.',
                        'Deploy DBT jobs to production and monitor their performance and health.',
                        'Create and maintain DBT documentation that describes the data sources, models, and metrics.',
                        'Collaborate with data analysts, data scientists, and business stakeholders to understand their data needs and provide data solutions.',
                        'Stay updated with the latest DBT features and best practices and leverage them to improve our data development process.'],
      Requirements:[`Bachelor's degree in Computer Science, Engineering, Mathematics, or a related field.`,
                    '3-4 years of experience in data engineering, data warehousing, or data analysis.',
                    '1+ years of experience in using DBT (open source or cloud) to build and manage data pipelines.',
                    'Strong SQL skills and familiarity with data warehouse concepts and architectures.',
                    'Experience with cloud data platforms such as Snowflake, BigQuery, Redshift, or Databricks.',
                    'Experience with version control tools such as Git and CI/CD tools such as GitHub Actions or DBT Cloud.',
                    'Excellent communication and documentation skills.',
                    'Ability to work independently and collaboratively in a fast-paced environment.'],
      niceToHave:['DBT Analytics Engineering Certification or equivalent DBT training.',
                  'Experience with other data transformation tools such as Airflow, Prefect, Ask on Data, or Dagster.',
                  'Experience with data visualization tools such as Looker, Tableau, Helical Insight, or Power BI.',
                  'Knowledge of Python, R, or other programming languages for data analysis']
    }
  ];