import React, { useState } from "react";
import { doc, setDoc, serverTimestamp } from "firebase/firestore";

import "./ContactUs.css";
import FormInput from "./formInputes";
import './FormInput.css'
import { db } from "../firebase/firebasae";

const ContactUs = (props) => {
  // constructor(props) {
  //   super(props);
  //   this.state = { name: '', email: "", phone: "", message: "" };
  // }

  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (event) => {
    event.preventDefault();

    // console.log(email, name, phone, message);
    try {
      await setDoc(doc(db, "contact", name), {
        name: name,
        email: email,
        phone: phone,
        message: message,
        timeStaml: serverTimestamp()
      });

      alert("Hello " + name + "\n" + "Your details has been captured!\nWe will contact you in comming days!")

    }
    catch (error) {
      console.log(error);
    }
    document.getElementById("form").reset();
  }

  const handleKeyDown = (e) => {
    if (e.which === 38 || e.which === 40) {
      e.preventDefault();
    }
  }

  return (
    <>
      <div className="container">
        <div className="firstBlock">
          <h1 className="formh1"> CONTACT </h1>
          <div className="thanksMessage"> Thanks for visiting our website.</div>
          <div className="thanksMessage"> Awaiting to hear from you. </div>
          <div className="rows">
            <div className="circleIcon">
              <div className="boxIcon">
                <box-icon
                  name="envelope"
                  type="solid"
                  flip="horizontal"
                  // color="#fdfcfc"
                  color='white'
                  size='xs'
                ></box-icon>
              </div>
            </div>
            <div><b>Email</b> : info@codeza.in</div>
          </div>

          <div className="rows">
            <div className="circleIcon">
              <div className="boxIcon">
                <box-icon
                  name="phone-call"
                  type="solid"
                  // color="#fdfcfc"
                  color='white'
                  size='xs'
                ></box-icon>
              </div>
            </div>
            <div><b>Mobile</b> : +91-7263030393</div>
          </div>
          <div className="rows">
            <div className="circleIcon">
              <div className="boxIcon">
                <box-icon
                  name="location-plus"
                  type="solid"
                  flip="horizontal"
                  // color="#fdfcfc"
                  color='white'
                  size='xs'
                ></box-icon>
              </div>
            </div>
            <div><b>Address</b> : Blossom Apartment, Flat No 6, Sr.No. 23/1B/39, Baner, Pune, MH, IN. 411045</div>
          </div>

        </div>
        <div className="formBackground">
          <form onSubmit={(e) => handleSubmit(e)} className="form" id="form">
            <h1 className="formh1"> LET'S CONNECT</h1>
            <div className="formInputs">
              <label className="label">Name</label>
              <input className="input" width="280px" name="name" placeholder="Name" type="text" onChange={(e) => setName(e.target.value)} required />
            </div>

            <div className="formInputs">
              <label className="label">Email</label>
              <input className="input" width="280px" name="email" placeholder="Email" type="email" onChange={(e) => setEmail(e.target.value)} required />
            </div>

            <div className="formInputs">
              <label className="label">Phone</label>
              <input className="input" width="280px" name="phone" placeholder="Phone" type="number" onChange={(e) => setPhone(e.target.value)}
                onKeyDown={(e) => handleKeyDown(e)} required />
            </div>

            <div className="formInputs">
              <label className="label">Message</label>
              <textarea className="input" width="280px" name="message" placeholder="Message" type="text" onChange={(e) => setMessage(e.target.value)} />
            </div>

            <input type="submit" value="Submit" className="submitButton" />

          </form>
        </div>

      </div>
      <div className="letsWorkTogether">
        Let's work together!
      </div>
    </>
  );
}

export default ContactUs;
