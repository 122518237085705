export const wwdList = [
    {
        title: 'Data warehouse advisory services',
        discription: "",
        listDes: 'Our data warehouse advisory services may include:',
        list: [{ label: 'DWH solution design:', children: ['DWH requirements engineering.', 'Business case creation.', 'DWH solution architecture.', 'DWH tech selection, the outline of the optimal cloud data warehouse platform and its configuration*.', 'Data governance design for data quality, availability, and security.', 'Data modeling, ETL/ELT design, etc.'] },
        { label: 'DWH implementation / migration / optimization plan.' },
        { label: 'Consulting support or complete project management.' }]
    },
    {
        title: 'Data warehouse implementation',
        discription: "Our team builds a DWH tailored to your unique data consolidation and storage needs and implements it into your ecosystem.",
        listDes: 'We offer:',
        list: [{ label: "Data warehouse requirements engineering." },
        { label: "Data warehouse solution conceptualization and platform selection." },
        { label: "Data warehouse solution architecture design." },
        { label: "Data warehouse system analysis." },
        { label: "Data modeling and ETL/ELT design." },
        { label: "Data warehouse solution development." },
        { label: "Data warehouse quality assurance and launch." },
        { label: "Data warehouse after-launch support." }
        ]
    },
    {
        title: 'Data warehouse migration',
        discription: "Codeza helps you optimize DWH performance and lower the total cost of ownership by moving your existing on-premises data warehouse to the cloud with no business process disruptions.",
        listDes: 'Codeza helps you migrate your legacy DWH solution to the cloud or build a hybrid data warehouse by:',
        list: [{ label: "Outlining a migration strategy and a plan." },
        { label: "Designing a cloud data warehouse architecture." },
        { label: "Assisting in selecting the right cloud vendor*." },
        { label: "Configuring the cloud cluster in a way to optimize costs." },
        { label: "Redeveloping a data warehouse on a new platform." },
        { label: "Integration of cloud and on-premises environments." },
        { label: "Transferring both master data and metadata to the new data warehouse." },
        { label: "Testing the completeness of data to ensure the migration’s success." }
        ]
    },
    {
        title: 'Data warehouse testing',
        discription: "Codeza offers a comprehensive DWH testing set, which can include ETL/ELT testing, BI testing, DWH performance testing, and security testing.",
        listDes: 'Codeza DWH testing services have the following stages:',
        list: [{ label: "Studying project requirements." },
        { label: "Test planning and test design." },
        { label: "Test implementation." },
        { label: "Result analysis and accountability." },
        ]
    },
    {
        title: 'Data warehouse support',
        discription: "Codeza provides DWH support to help you identify and solve DWH performance issues, achieve DWH stability for timely and quality data flow for business users, and lower DWH storage and processing costs.",
        listDes: 'Codeza’s team offers:',
        list: [{ label: "DWH solution architecture optimization." },
        { label: "Optimization of individual DWH tools (keeping more data in memory, adding indexes to tune query performance)." },
        { label: "DWH design optimization (changing database schemas, data loading, etc.)." },
        ]
    }
]
