export const ContractStaffingData = ["Temporary / Contract staffing is when employees are hired to perform specific functions in a contractual basis for a client company for a defined period of time.",
    "Flexi staffing is a great option when the organization has an immediate need for more hands for their current project or when the Company has a hiring freeze but has deadlines to meet their project deadlines",
    "Benefits of Contract Staffing are many ranging from meeting project deadlines to eliminating pay-roll accounting and maintaining budget controls and most importantly eliminating bad press from layoffs during recession.",
    "Codeza Solutions Private Limited provides Contract Staffing solutions in all the verticals which help organizations meet their project deadlines within fixed cost. We provide these services in China, Africa, North and South America, Singapore, Europe, Australia & New Zealand the Far East and the Middle East."]

export const InternationalRecruitmentData = ["We provide international recruitment services under our International Talent Acquisition Services. The talent acquisition service is provided as per changing staffing requirements across divergent time zone, companies and industries. With the assistance of our continuous improvement strategy,we have been able to establish strategic tie-ups in international market with various companies. We have all required license and permissions from Government of India to serve global market.",
    "We at Codeza Solutions Private Limited, screen best candidates by keeping in mind the industry specific ability and work zone locations. Our company serves the staffing requirements of organizations inChina, Africa, North and South America, Singapore, Europe, Australia & New Zealand,the Far East and the Middle East."]

export const RecruitmentProcessData = ["Acumontrecognizes the necessity of expertise and process management in a rapidly changing economic scenario. The recruitment process outsourcing solutions incorporates speed, flexibility and accountability to the recruitment process.",
    "At Codeza Solutions Private Limited we work closely with the organizations management team to understand their future strategy and man power requirements in coming future. This enables us to be efficient in our entire recruitment and on boarding process."]

export const StartupHiringData = ["Startups bring good energy and enthusiasm to the industry and many a times create a leading product,services and jobs revolution. For startups to sustain their energy and growth, manpower with diverse skill sets is required.Startup entrepreneurs would accept that although they own the idea and have the enthusiasm, they lack expertise in other skill areas which are required for startup to grow such as marketing,finance, engineering, logistics and so on. At the same time recruitment for startups is challenging because the best of these, whether in India or overseas, startups take a while to establish their brand goodwill.",
    "For Startups to be successful having the right team at the right time in the company’s growth curve is very important to enable the company’s success. This is where Codeza Solutions Private Limited, an end to end recruitment firm provides start-up hiring solutions which helps startups to turn the challenge into opportunity. Our premier staffing solutions and recruitment consultancy for Start-ups helps in building a platform for Start-ups companies to connect with the best talent in various industry verticals within India and overseas."]